import VueApexCharts from 'vue-apexcharts'
import axiosAuth from '@/plugins/axiosAuth.js'
import Approvals from './../Approvals/Approvals.vue'

export default {
    name: 'Dashboard',
    components: {
        VueApexCharts,
        Approvals
    },
    data() {
      return {
        summary: {
          total_transaction_count : null,
          total_transaction_amount : null,
        },
        search : {
          warehouseId : null,
          categoryId : null
        },
        valueReport: 1,
        filterDateSummary: '',
        filterDate: '',
        chart_load: false,
        chartDatas: null,
        loading_chart: true,
        chartOptions: {
            chart: {
            id: 'vuechart-example'
            },
            xaxis: {
                labels : {
                    datetimeUTC: false,
                },
                type: 'datetime',
                tickAmount: 1,
            },
            yaxis: {
                labels: {
                  formatter: function (value) {
                    return value;
                  }
                },
              },
            tooltip: {
                enabled: true,
                x: {
                    format: 'dd MMM yyyy'
                },

                y: {
                  formatter: function(val) {
                      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                  },
                },

                z: {
                  formatter: function(val) {
                      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' Ks'
                  },
                  title: 'Sales : '
                },
            },
            colors: ["#FF9939"],
        },
        chartDimensionOptions: {
          chart: {
            type: 'donut',
          },
          labels: [],
          legend: {
            formatter: function(val, opts) {
              return val + " - " + opts.w.globals.series[opts.seriesIndex] + ' Transaction'
            }
          },
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
        },
      seriesDimension: [],
      dimension_chart_loaded: false,
        pickerOptions: {
            shortcuts: [{
                text: 'Last week',
                onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                picker.$emit('pick', [start, end]);
                }
            }, {
                text: 'Last month',
                onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                picker.$emit('pick', [start, end]);
                }
            }, {
                text: 'Last 3 months',
                onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                picker.$emit('pick', [start, end]);
                }
            }]
        },
        warehouseOptions: [],
        catOptions: [],
      }
    },
    watch: {
  },
    methods: {

      initFilterDate() {
        var date = new Date(), y = date.getFullYear(), m = date.getMonth();
        var firstDay = new Date(y, m, 1);
        var lastDay = new Date(y, m + 1, 0);

        this.filterDateSummary = [firstDay, lastDay]
        this.filterDate = [firstDay, lastDay]
      },
        
        getSummary() {
          let self = this
            this.summary = {
            total_transaction_count : null,
            total_transaction_amount : null,
          }
          let paramsQ = {
            startDate : self.filterDateSummary !== '' && self.filterDateSummary !== null ? Moment(this.filterDateSummary[0]).format('YYYY-MM-DD') : Moment(startDateNow).format("YYYY-MM-DD"),
            endDate : self.filterDateSummary !== '' && self.filterDateSummary !== null ? Moment(this.filterDateSummary[1]).format('YYYY-MM-DD') : endDateNow,
            warehouseId: this.search.warehouseId,
            categoryId: this.search.categoryId
          }

          let yearNow = Moment().format('YYYY');
          let monthNow = Moment().format('MM');
          let startDateNow = yearNow + '-' + monthNow + '-01';
          let endDateNow = Moment().endOf('month').format('YYYY-MM-DD');

          // axiosAuth.get('admin/dashboards/summary', {
          //   headers: {
          //       Authorization: 'Bearer ' + this.$localStorage.get('accessTokenJapfaAdmin')
          //   },
          //   params: paramsQ
          // }).then(response=>
          // {
          //     let data = response.data
          //   if(data) {
          //     this.summary = {
          //       total_transaction_count : data.total_transaction_count,
          //       total_transaction_amount : data.total_transaction_amount,
          //     }
          //   }
          // }).catch(error =>
          // {
          // })
          
        },

        async getChartOverview() {
          let self = this
          this.loading_chart = true;

          self.chartDatas = []

          let yearNow = Moment().format('YYYY');
          let monthNow = Moment().format('MM');
          let startDateNow = yearNow + '-' + monthNow + '-01';
          let endDateNow = Moment().endOf('month').format('YYYY-MM-DD');
          let paramsQ = {
              startDate : self.filterDateSummary !== '' && self.filterDateSummary !== null ? Moment(this.filterDateSummary[0]).format('YYYY-MM-DD') : Moment(startDateNow).format("YYYY-MM-DD"),
              endDate : self.filterDateSummary !== '' && self.filterDateSummary !== null ? Moment(this.filterDateSummary[1]).format('YYYY-MM-DD') : endDateNow,
              warehouseId: this.search.warehouseId,
              categoryId: this.search.categoryId
          }

          let dataChart = []
          //   await axiosAuth.get('admin/dashboards/chart', {
          //     headers: {
          //         Authorization: 'Bearer ' + this.$localStorage.get('accessTokenJapfaAdmin')
          //     },
          //     params: paramsQ
          // }).then(async response=>
          // {
          //     response.data.forEach(async el => {
          //         el.only_date_chart = Moment(el.date).format("MM/DD/YYYY")
          //         dataChart.push([el.only_date_chart,Number(el.total_count),Number(el.total_amount)]);

          //     });

          // }).catch(error =>
          // {
          //     this.loading_chart = false;
          // })

          self.chartDatas =  dataChart
          self.series = [];
          self.series.push({
              name : 'Transaction',
              data : self.chartDatas 
          }) 
              this.loading_chart = false;
      },

      async getPieChart() 
        {
            let self = this
            // this.loading_chart = true;

            self.dimension_chart_loaded = false;
            self.seriesDimension = [];
            self.chartDimensionOptions.labels = [];
            let yearNow = Moment().format('YYYY');
            let monthNow = Moment().format('MM');
            let startDateNow = yearNow + '-' + monthNow + '-01';
            let endDateNow = Moment().endOf('month').format('YYYY-MM-DD');
            let paramsQ = {
                startDate : self.filterDate !== '' && self.filterDate !== null ? Moment(this.filterDate[0]).format('YYYY-MM-DD') : Moment(startDateNow).format("YYYY-MM-DD"),
                endDate : self.filterDate !== '' && self.filterDate !== null ? Moment(this.filterDate[1]).format('YYYY-MM-DD') : endDateNow,
                warehouseId: null,
                categoryId: null
            }

            let dataChart = []
            await axiosAuth.get('admin/dashboard/transactions/category', {
                headers: {
                    Authorization: 'Bearer ' + this.$localStorage.get('accessTokenJapfaAdmin')
                },
                params: paramsQ
            }).then(async response=>
            {
              console.log(response)
                if(response.data) {
                    if(response.data.length && response.data.length > 0) {
                      response.data.forEach(async el => {
                          self.chartDimensionOptions.labels.push(el.name);
                          self.seriesDimension.push(el.total);
                      });
                    }
                }


            }).catch(error =>
            {
                // this.loading_chart = false;
            })

            setTimeout(()=> {
                self.dimension_chart_loaded = true;
            }, 500)

                // this.loading_chart = false;
        },


        getMasterData() {
          this.warehouseOptions = [];
          this.catOptions = [];

          let searchQ = {
              limit: 1000,
              page: 1
          };

          this.$store.dispatch('getWarehouse', searchQ)
          .then(res => {
              this.warehouseOptions = res;
          })

            this.$store.dispatch('getProductCat', searchQ)
            .then(res => {
                this.catOptions = res;
            })
      },
        
        numberFormat(data) {
            return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      },
    },
    mounted() {
      document.title = 'Dashboard - Kujangmas Admin'
      this.getMasterData();
      this.initFilterDate();
      this.getSummary();
      this.getChartOverview();
    //   this.getPieChart();

      // this.$watch('filterDateSummary', _.debounce((newVal) => {
      //   this.getSummary();
      //   this.getChartOverview();
      // }, 300));

      // this.$watch('search.warehouseId', _.debounce((newVal) => {
      //   this.getSummary();
      //   this.getChartOverview();
      // }, 300));

      // this.$watch('search.categoryId', _.debounce((newVal) => {
      //   this.getSummary();
      //   this.getChartOverview();
      // }, 300));

      // this.$watch('filterDate', _.debounce((newVal) => {
        // this.getChartOverview();
        // this.getPieChart();
    // }, 700));

    //   this.$watch('chartDatas', _.debounce((newVal) => {
    //     if(this.chartDatas && this.chartDatas.length > 0)
    //   {
    //           setTimeout(()=> {
    //               this.chart_load = true;
    //       }, 500)
    //   }else{  
    //           this.chart_load = false;

    //   }
    // }, 300));
    }
  }