<template>
    <div>
        <switches v-model="pre_enabled" theme="bootstrap" color="success"></switches>
    </div>
</template>

<script>
import Switches from 'vue-switches';

export default {
    name: 'SwitchActive',
    components: { Switches },
    props: {
        enabled: {
            type: Boolean,
            default: false
        },
        id: {
            type: Number
        },
        name: {
            type: String
        },
        loadData: {
            type: Function
        },
    },
    data() {
        return {
            pre_enabled: this.enabled
        }
    },
    watch: {
        async pre_enabled(newVal) {
            let message = newVal ? "Diaktifkan" : "Dinonaktifkan"
            const dataNeed = {
                id: this.id,
                form: {
                    id: this.id,
                    status: newVal
                }
            }
            await this.$store.dispatch('ToggleStatusProduct',dataNeed).then(res => {
                    swal.fire(
                    'Success',
                    'Produk '+ this.name +' Berhasil '+message,
                    'success'
                )
                this.loadData()
            }).catch(err => {
                this.loadData()
                swal.fire(
                    'Error',
                    'Produk Gagal '+message,
                    'error'
                )
            })
        }
    },
    method: {
        toggleStatusActive() {
            // alert('tes')
            // swal.fire({
            //     title: 'Apakah Kamu yakin?',
            //     text: 'Kamu akan menonaktifkan pengajuan Toko ' + nama,
            //     icon: 'warning',
            //     showCancelButton: true,
            //     confirmButtonText: 'Ya, Setujui',
            //     cancelButtonText: 'Batalkan'
            // }).then(async result => {
            //     if (result.value) {
            //         // const dataNeed = {
            //         //     id: id,
            //         //     form: {
            //         //         id: id,
            //         //         status: 'approved'
            //         //     }
            //         // }
            //         // await this.$store.dispatch('UpdateStatusStore',dataNeed).then(res => {
            //         //         swal.fire(
            //         //         'Success',
            //         //         'Setujui Toko '+ nama +' Berhasil!',
            //         //         'success'
            //         //     )
            //         //     this.loadData()
            //         // }).catch(err => {
            //         //     swal.fire(
            //         //         'Error',
            //         //         'Setujui Toko Gagal!',
            //         //         'error'
            //         //     )
            //         // })
            //     }
            // }).catch(function (error) {
            //     console.log(error)
            // });
        },
    },
}
</script>