
export default {
    name: 'Stock',
    data() {
        return {
            form:{
                name: 'Veronica',
                company: 'Veridian Dynamics'
            },
            search : {
                keyword : '',
                warehouseId: null
            },
            modeForm: 'add',
            loading: true,
            isMode : 'show',
            total_items: 0,
            currentPage: 1,
            isContent : 'Barang',
            listHead : [
                {
                    title: 'Barang',
                    notif : null
                }
            ],
             options: [
                { value: '', text: 'Pilih Unit', disabled: true },
                { value: 'bal', text: 'bal' },
                { value: 'dus', text: 'dus' },
                { value: 'pak', text: 'pak' },
                { value: 'krg', text: 'krg' },
                { value: 'kg', text: 'kg' },
                { value: 'pcs', text: 'pcs' },
                { value: 'rcg', text: 'rcg' },
                { value: 'peti', text: 'peti' },
            ],
            dataList : {
                data : [],
                detailData: {},
                _loading : true,
                pagination: {
                    "page": 1,
                    "total_pages": 1,
                    "total_records": 1,
                    "prev": 1,
                    "next": 1,
                    "record_per_page": 1
                },
                limit: 5,
                form : [
                    {
                        code : '',
                        name : '',
                        unit : '',
                        capital : null,
                    }
                ]
            },
            editForm : {
                warehouse_id : null,
                stock : null,
                weight_per_item : null,
                price : null,
            },
            editType : null,
            warehouseOptions: [],
        }
    },

    watch: {
        // "search.keyword"()
        // {
        //     if(this.search.keyword !== '')
        //     {
        //             this.loadData()
        //     }else
        //     {
        //             this.loadData()
        //     }
        // },
        // "currentPage"()
        // {
        //     let self = this

        //     if(self.currentPage != 1)
        //     {
        //             self.loadData()
        //     }else {
        //             self.loadData()
        //     }
        // },
    },
    computed : {
        totalItem(){

            return this.total_items
        },
    },

    methods: {
        numberFormat(data) {
            return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        closeModal(e) {
            this.$bvModal.hide('modal-product-form')
            this.loadData()
        },
        initForm()
        {
            this.dataList.form = [
                    {
                        code : '',
                        name : '',
                        unit : '',
                        capital : null,
                    }
                ]
        },
        addForm()
        {
            this.modeForm = 'add';
            this.$bvModal.show('modal-product-form')
            
        },
        removeForm(x)
        {
            if(this.dataList.form.length == 1)
            {
                return null
            }else {
                return  this.dataList.form.splice(x, 1);
            }
            
        },
        editData(data, type)
        {
            this.editType = type;
            this.dataList.detailData = data
            if(type === 'restock') {
                this.editForm = {
                    warehouse_id : this.dataList.detailData.warehouse_id,
                    stock : null,
                    weight_per_item : null,
                }

                // if(this.dataList.detailData)
            }else{
                this.editForm = {
                    warehouse_id : this.dataList.detailData.warehouse_id,
                    price : this.dataList.detailData.price,
                }
            }
            this.$bvModal.show('modal-variant-restock')
        },
        saveEdit() {
            if(this.editType === 'restock') {
                this.editForm.stock = Number(this.editForm.stock);

                if(this.dataList.detailData.category_id === 1) {
                    this.editForm.weight_per_item = Number(this.editForm.weight_per_item);
                }
            }else {
                this.editForm.price = Number(this.editForm.price);
            }
            
            let dataNeed = {
                id : this.dataList.detailData.variant_id,
                type : this.editType,
                form : this.editForm,
            }

            this.$store.dispatch('updateVariantData',dataNeed).then(res => {
                swal.fire(
                    'Success',
                    this.editType === 'restock' ? 'Restock ' : 'Edit Price ' +' Variant Success!',
                    'success'
                )
                this.$bvModal.hide('modal-variant-restock')
                this.loadData()
            }).catch(err => {
                swal.fire(
                    'Error',
                    this.editType === 'restock' ? 'Restock ' : 'Edit Price ' +' Variant Failed!',
                    'error'
                )
            })
        },

        deleteData(id, nama)
        {
            let self = this;
            swal.fire({
                title: 'Are You Sure?',
                text: 'Promo '+ nama +' will delete permanently!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel'
                }).then(async result => {
                if (result.value) {
                    await this.$store.dispatch('DeletePromo',id).then(res => {
                            swal.fire(
                            'Success',
                            'Delete Promo '+ nama +' Success!',
                            'success'
                        )
                        this.loadData()
                    }).catch(err => {
                        swal.fire(
                            'Error',
                            'Delete Promo Failed!',
                            'error'
                        )
                    })
                    }
                }).catch(function(error) {
                        console.log(error)
                    });
        },
        numberFormat(data) {
            return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        loadData(page)
        {
            let self = this;
            page = page || 1

            self.dataList._loading = true
            self.dataList.data = []
            let searchQ = {
                keyword: self.search.keyword,
                limit: Number(this.dataList.limit),
                page: page,
                warehouseId: this.search.warehouseId
            };

            self.$store.dispatch('getVariantStockList', searchQ)
            .then(res => {
                console.log(res)
                if(res.data && res.data.length > 0)
                {
                    res.data.forEach(el => {
                        el.end_date_formatted = Moment(el.end_date).format('DD MMM YYYY')
                        self.dataList.data.push(el)
                    })
                }
                self.dataList.pagination = res.meta
                self.total_items = res.meta.total_records

                setTimeout(() => {
                    self.dataList._loading = false;
                        
                    }, 600);
                })
            .catch(() => {
            self.total_items = 0
            self.dataList._loading = false;
            });
        },
        getOverallIndex(index) {
            return (this.dataList.pagination.page* this.dataList.limit)-this.dataList.limit + index + 1
          },
        getMasterData() {
            this.warehouseOptions = [];
            let searchQ = {
                limit: 1000,
                page: 1
            };

            this.$store.dispatch('getWarehouse', searchQ)
            .then(res => {
                this.warehouseOptions = res;
            })
        },

    },
    mounted()
    {
      document.title = 'Stock - Kujangmas Admin';
      this.getMasterData();
        this.loadData();
    }
}