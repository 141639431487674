import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Page/Dashboard/Dashboard.vue'
import Product from '../views/Page/Product/Product.vue'
import AddProduct from '../views/Page/Product/Form-Products/Form-Products.vue'
import Promo from '../views/Page/Promo/Promo.vue'
import Stock from '../views/Page/Stock/Stock.vue'
import Users from '../views/Page/Users/Users.vue'
import Store from '../views/Page/Store/Store.vue'
import Transaction from '../views/Page/Transaction/Transaction.vue'
import Terms from '../views/Page/Terms/Terms.vue'
import About from '../views/Page/About/About.vue'
import Warehouse from '../views/Page/Warehouse/Warehouse.vue'
import WarehouseStock from '../views/Page/WarehouseStock/WarehouseStock.vue'
import WarehouseTransit from '../views/Page/WarehouseTransit/WarehouseTransit.vue'
import Approvals from '../views/Page/Approvals/Approvals.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
			auth: true,
			displayName: 'Dashboard'
		}
  },
  {
    path: '/product',
    name: 'Product',
    component: Product,
    meta: {
			auth: true,
			displayName: 'Produk'
		}
  },
  {
    path: '/product/add',
    name: 'AddProduct',
    component: AddProduct,
    meta: {
			auth: true,
			displayName: 'Tambah Produk'
		}
  },
  {
    path: '/product/:id',
    name: 'Detailroduct',
    component: AddProduct,
    meta: {
			auth: true,
			displayName: 'Detail Produk'
		}
  },
  {
    path: '/stock',
    name: 'Stock',
    component: Stock,
    meta: {
			auth: true,
			displayName: 'Stock'
		}
  },
  {
    path: '/transaction',
    name: 'Transaction',
    component: Transaction,
    meta: {
			auth: true,
			displayName: 'Transaksi'
		}
  },
  {
    path: '/payment',
    name: 'Payment',
    component: Approvals,
    meta: {
			auth: true,
			displayName: 'Payment'
		}
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    meta: {
			auth: true,
			displayName: 'Pengguna'
		}
  },
  {
    path: '/store',
    name: 'Store',
    component: Store,
    meta: {
			auth: true,
			displayName: 'Toko'
		}
  },
  {
    path: '/promo',
    name: 'Promo',
    component: Promo,
    meta: {
			auth: true,
			displayName: 'Promo'
		}
  },
  {
    path: '/warehouse',
    name: 'Warehouse',
    component: Warehouse,
    meta: {
			auth: true,
			displayName: 'Gudang'
		}
  },
  {
    path: '/warehouse-stock',
    name: 'WarehouseStock',
    component: WarehouseStock,
    meta: {
			auth: true,
			displayName: 'Gudang Stok'
		}
  },
  {
    path: '/warehouse-transit',
    name: 'WarehouseTransit',
    component: WarehouseTransit,
    meta: {
			auth: true,
			displayName: 'Gudang Transit'
		}
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Page/Login/Login.vue')
  },
  {
    path: '/terms-condition',
    name: 'TermsCondition',
    component: Terms,
    meta: {
			auth: false,
			displayName: 'Terms'
		}
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
			auth: false,
			displayName: 'About'
		}
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) =>
{
    if (to.matched.some((record) => record.meta.auth))
    {
      if(Vue.localStorage.get('accessTokenKujangMasAdmin') && Vue.localStorage.get('accessTokenKujangMasAdmin') != '')
      {
        next();
      }
      else
      {
        next('/login');
      }
    }else {
      next();
    }

	next();

});

router.afterEach((to, from) => {
	window.scroll(0,0);
  });

export default router
