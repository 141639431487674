export default {
    name: "FormStore",
    data() {
        return {
            process: false,
            storeId: null,
            imgTemp: null,
            dialogImageUrl: '',
            imgTempKtp: null,
            dialogImageKtpUrl: '',
            form: {
                address: "",
                code: "",
                credit_limit: null,
                description: "",
                image_url: "",
                latitude: null,
                longitude: null,
                name: "",
                pic_ktp_photo: "",
                pic_name: "",
                pic_phone_number: ""
            },
        }
    },

    props: {
        formData: {
            default: null,
            type: Object,
            required: true
        },
        formMode: {
            default: '',
            type: String,
            required: true
        },
    },

    computed: {
        disabledSave() {
            let invalid = false;

            if(this.form.code === '' || this.form.code === null) {
                invalid = true;
            }
            if(this.form.name === '' || this.form.name === null) {
                invalid = true;
            }
            if(this.form.pic_name === '' || this.form.pic_name === null) {
                invalid = true;
            }
            if(this.form.pic_phone_number === '' || this.form.pic_phone_number === null) {
                invalid = true;
            }
            if(this.form.credit_limit === '' || this.form.credit_limit === null) {
                invalid = true;
            }

            return invalid
        }
    },

    methods: {
        selectFile(i = null)
        {
                $('#inputIMG-'+i).click();
        },
        previewImageKtp: function(event) {
            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.dialogImageKtpUrl = e.target.result;
                }
                reader.readAsDataURL(input.files[0]);
            }
        },
        previewImage: function(event) {
            var input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.dialogImageUrl = e.target.result;
                }
                reader.readAsDataURL(input.files[0]);
            }
        },
        initForm() {
            if(this.formMode === 'edit') {
                this.storeId = this.formData.id;
                this.form.address= this.formData.address;
                this.form.code= this.formData.code;
                this.form.credit_limit= this.formData.credit_limit;
                this.form.description= this.formData.description;
                this.form.image_url= this.formData.image_url;
                this.form.latitude= this.formData.latitude;
                this.form.longitude= this.formData.longitude;
                this.form.name= this.formData.name;
                this.form.pic_ktp_photo= this.formData.pic_ktp_photo;
                this.form.pic_name= this.formData.pic_name;
                this.form.pic_phone_number= this.formData.pic_phone_number;

                this.dialogImageUrl = this.formData.image_url? this.formData.image_url: '';
                this.dialogImageKtpUrl = this.formData.pic_ktp_photo? this.formData.pic_ktp_photo: '';
            }else{
                    this.form = {
                        address: "",
                        code: "",
                        credit_limit: null,
                        description: "",
                        image_url: "",
                        latitude: null,
                        longitude: null,
                        name: "",
                        pic_ktp_photo: "",
                        pic_name: "",
                        pic_phone_number: ""
                    }
            }
        },

        async saveData() {
            let self = this;

            self.process = true;

            if(self.imgTemp) {
                let fd = new FormData();
                fd.append('file', self.imgTemp);
                fd.append('filename', self.imgTemp.name);

                await self.$store.dispatch('uploadFile', fd)
                .then(async res => {
                    this.form.image_url = await res.data.url
                }).catch(err => {
                   return this.$bvToast.toast(
                        err.message,
                        {
                        title: "Upload image Gagal",
                        variant: "danger",
                        autoHideDelay: 5000,
                        appendToast: false,
                        toaster: "b-toaster-top-right"
                        }
                    );
                })
            }

            if(self.imgTempKtp) {
                let fd = new FormData();
                fd.append('file', self.imgTempKtp);
                fd.append('filename', self.imgTempKtp.name);

                await self.$store.dispatch('uploadFile', fd)
                .then(async res => {
                    this.form.pic_ktp_photo = await res.data.url
                }).catch(err => {
                   return this.$bvToast.toast(
                        err.message,
                        {
                        title: "Upload image Gagal",
                        variant: "danger",
                        autoHideDelay: 5000,
                        appendToast: false,
                        toaster: "b-toaster-top-right"
                        }
                    );
                })
            }

            setTimeout(() => {
                    let dataNeed = {
                        id : this.storeId,
                        form : this.form
                    }

                    dataNeed.form.created_at = this.formData.created_at;
                    dataNeed.form.credit_limit = Number(dataNeed.form.credit_limit);
                    dataNeed.form.latitude = dataNeed.form.latitude ? Number(dataNeed.form.latitude) : null;
                    dataNeed.form.longitude = dataNeed.form.latitude ? Number(dataNeed.form.longitude) : null;
                    self.$store.dispatch('UpdateStore', dataNeed)
                    .then(res => {
                        this.$emit("closeModal", true);
                        swal.fire(
                            'Success!',
                            'Update Store Berhasil!',
                            'success'
                            )
                        self.process = false;
                    })
                    .catch(err => {
                        this.$emit("closeModal", true);
                        swal.fire(
                            'Failed!',
                            'Update Store Gagal. '+ err.message,
                            'error'
                        )
                        self.process = false;
                    })
            }, 500);
            
        },

    },
    mounted() {
        this.initForm();
    }
}