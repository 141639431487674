import { onMounted } from "vue";

export default {
    props: {
        id: {
            type: "string"
        },
        loadTable: {
            type: "Function"
        },
    },
    data() {
        return {
            form: {
                "amount": 0,
                "created_at": "",
                "created_by": "",
                "id": 0,
                "order_id": 0,
                "payment_method": "",
                "payment_type": "",
                "proof_paid_transfer_url": "",
                "reason": "",
                "status": "",
                "updated_at": "",
                "updated_by": ""
            },
            process: false,            // Untuk menampilkan spinner saat proses sedang berlangsung
            disabledSave: false        // Menonaktifkan tombol saat proses berjalan atau form tidak valid
        };
    },
    methods: {
        // Metode untuk menangani penolakan pembayaran
        reject() {
            this.process = true;
            let self = this;

            self.$store.dispatch('paymentApproval', {
                id: self.id,
                data: {
                    "status": "declined",
                    "reason": ""
                }
            }).then(res => {
                    this.process = false;
                    swal.fire(
                        'Success!',
                        'Reject Success!',
                        'success'
                    )
                    this.closeModal()
                })
                .catch(() => {
                    this.process = false;
                });
        },
        // Metode untuk menangani persetujuan pembayaran
        loadData() {
            let self = this
            this.process = true;
            self.$store.dispatch('getPaymentDetailApproval', { id: self.id })
                .then(res => {
                    this.process = false;
                    const { data } = res;
                    if (res.data) {
                        self.form = data
                    }
                })
                .catch(() => {
                    // self.total_items = 0
                    // self._loading = false;
                });
        },
        closeModal() {
            this.$bvModal.hide('modal-detail')
            this.form = {
                "amount": 0,
                "created_at": "",
                "created_by": "",
                "id": 0,
                "order_id": 0,
                "payment_method": "",
                "payment_type": "",
                "proof_paid_transfer_url": "",
                "reason": "",
                "status": "",
                "updated_at": "",
                "updated_by": ""
            }
            this.loadTable()
        },
        // Metode untuk menangani persetujuan pembayaran
        approve() {
            this.process = true;
            let self = this
            self.$store.dispatch('paymentApproval', 
                {
                    id: self.id,
                    data: {
                        "status": "accepted",
                        "reason": ""
                    }
                }
            ).then(res => {
                    this.process = false;
                    swal.fire(
                        'Success!',
                        'Approve Success!',
                        'success'
                    )
                    this.closeModal()
                })
                .catch(() => {
                    this.process = false;
                });
        },
        // Metode untuk memvalidasi input form sebelum mengizinkan submit
        validateForm() {
            this.disabledSave = !this.form.name || !this.form.amount || !this.form.payment_method;
        }
    },
    watch: {
        // Memantau perubahan input form dan memanggil validateForm untuk validasi real-time
        form: {
            handler: 'validateForm',
            deep: true
        }
    },
    mounted() {
        this.loadData()
    }
};
