export default {
    name: "FormUser",
    data() {
        return {
            process: false,
            userId: null,
            form: {
                email: "",
                name: "",
                password: "",
                role: "admin",
            },
            options: [
               {
                label: 'Admin',
                value: 'admin'
               },
               {
                label: 'Sales',
                value: 'sales'
               }
            ]
        }
    },

    props: {
        formData: {
            default: null,
            type: Object,
            required: true
        },
        formMode: {
            default: '',
            type: String,
            required: true
        },
    },

    computed: {
        disabledSave() {
            let invalid = false;

            if(this.form.email === '' || this.form.email === null) {
                invalid = true;
            }
            if(this.form.name === '' || this.form.name === null) {
                invalid = true;
            }
            if(this.formMode === 'add' && this.form.password === '' || this.form.password === null) {
                invalid = true;
            }

            return invalid
        }
    },

    methods: {
        initForm() {
            if(this.formMode === 'edit') {
                this.userId = this.formData.id;
                this.form.email= this.formData.email;
                this.form.name= this.formData.name;
                this.form.role= this.formData.role;
                this.form.password= this.formData.password;
            }else{
                    this.form = {
                        email: "",
                        name: "",
                        password: "",
                        role: "admin",
                    }
            }
        },

        async saveData() {
            let self = this;
            self.process = true;

            setTimeout(() => {
                if(this.formMode === 'add') {
                    self.$store.dispatch('addUser', this.form)
                    .then(res => {
                        this.$emit("closeModal", true);
                        swal.fire(
                            'Success!',
                            'Tambah Pengguna Berhasil!',
                            'success'
                            )
                        self.process = false;
                    })
                    .catch(err => {
                        this.$emit("closeModal", true);
                        swal.fire(
                            'Failed!',
                            'Tambah Pengguna Gagal. '+ err.message,
                            'error'
                        )
                        self.process = false;
                    })
                }else{
                    let dataNeed = {
                        id : this.userId,
                        form : this.form
                    }

                    delete dataNeed.form.password
                    self.$store.dispatch('UpdateUser', dataNeed)
                    .then(res => {
                        this.$emit("closeModal", true);
                        swal.fire(
                            'Success!',
                            'Update Pengguna Berhasil!',
                            'success'
                            )
                            self.process = false;
                    })
                    .catch(err => {
                        this.$emit("closeModal", true);
                        swal.fire(
                            'Failed!',
                            'Update Pengguna Gagal. '+ err.message,
                            'error'
                        )
                        self.process = false;
                    })
                }
            }, 500);
            
        },

    },
    mounted() {
        this.initForm();
    }
}