<template>
    <div>
        <div class="d-flex justify-content-between mt-3 mb-4">
            <div class="d-flex">
                <b-form @submit.prevent="loadData()">
                    <b-input-group>
                        <b-form-input style="width: 280px" type="text" v-model="search.keyword"
                            :placeholder="'Cari Nama Pengguna'"></b-form-input>
                        <b-input-group-append>
                            <b-button type="submit" variant="success" style="font-size:14px;"><i class="fa fa-search"
                                    aria-hidden="true"></i></b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form>
            </div>
        </div>
        <hr>
        <table class="table table-bordered table-checkbox">
            <thead>
                <tr>
                    <th scope="col">No</th>
                    <th scope="col">Tanggal</th>
                    <th width="20%">Order Id</th>
                    <th scope="col">Jumlah</th>
                    <th scope="col">Metode pembayaran</th>
                    <th scope="col">Status</th>
                    <th width="10%">Aksi</th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="dataList.data.length == 0 && !dataList._loading">
                    <td colspan="7" class="text-center"><b-alert show variant="warning">Data not found</b-alert></td>
                </tr>
                <tr v-if="!dataList._loading && dataList.data.length > 0" v-for="(each, idx) in dataList.data">
                    <td>{{ getOverallIndex(idx) }}</td>
                    <td>{{ each.date_formatted }}</td>
                    <td>{{ each.order_id }}</td>
                    <td>{{ each.amount }}</td>
                    <td>{{ each.payment_method }}</td>
                    <td>
                        <span :class="each.status == 'accepted' ? 'text-capitalize text-success' :
                            each.status == 'pending' ? 'text-capitalize text-warning' :
                                each.status == 'declined' ? 'text-capitalize text-danger' :
                                    'text-capitalize text-success'">
                            {{ each.status }}
                        </span>
                    </td>
                    <td>
                        <b-button variant="success mr-1" @click="showDetail(each)"><i class="fa fa-eye" aria-hidden="true" style="color: white; font-size: 12px;"></i></b-button>
                    </td>
                </tr>
                <tr v-if="dataList._loading">
                    <td colspan="8" class="text-center"><b-spinner type="grow" label="Loading..."></b-spinner></td>
                </tr>
            </tbody>
        </table>

        <div class="mt-3">
            <b-pagination pills align="center" v-model="dataList.pagination.page" @change="loadData"
                :per-page="dataList.limit" :total-rows="dataList.pagination.total_records" prev-text="Prev"
                next-text="Next"></b-pagination>
        </div>
        <b-modal id="modal-detail" :title="'Detail'" centered hide-footer no-close-on-backdrop>
            <DetailPayment :id="currentId" :loadTable="loadData" />
        </b-modal>
    </div>
</template>

<script>
import DetailPayment from './DetailPayment/DetailPayment.vue';
export default {
    name: 'Approvals',
    components: { DetailPayment },
    data() {
        return {
            currentId: null,
            form: {
                name: 'Veronica',
                company: 'Veridian Dynamics'
            },
            search: {
                keyword: ''
            },
            modeForm: 'add',
            loading: true,
            isMode: 'show',
            total_items: 0,
            currentPage: 1,
            isContent: 'Barang',
            listHead: [
                {
                    title: 'Barang',
                    notif: null
                }
            ],
            options: [
                { value: '', text: 'Pilih Unit', disabled: true },
                { value: 'bal', text: 'bal' },
                { value: 'dus', text: 'dus' },
                { value: 'pak', text: 'pak' },
                { value: 'krg', text: 'krg' },
                { value: 'kg', text: 'kg' },
                { value: 'pcs', text: 'pcs' },
                { value: 'rcg', text: 'rcg' },
                { value: 'peti', text: 'peti' },
            ],
            dataList: {
                data: [],
                detailData: {},
                _loading: true,
                pagination: {
                    "page": 1,
                    "total_pages": 1,
                    "total_records": 1,
                    "prev": 1,
                    "next": 1,
                    "record_per_page": 1
                },
                limit: 5,
                form: [
                    {
                        code: '',
                        name: '',
                        unit: '',
                        capital: null,
                    }
                ]
            }
        }
    },

    watch: {
        "currentPage"() {
            let self = this

            if (self.currentPage != 1) {
                self.loadData()
            } else {
                self.loadData()
            }
        },
    },
    computed: {
        totalItem() {

            return this.total_items
        },
    },

    methods: {
        numberFormat(data) {
            return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        closeModal(e) {
            this.$bvModal.hide('modal-product-form')
            this.loadData()
        },
        initForm() {
            this.dataList.form = [
                {
                    code: '',
                    name: '',
                    unit: '',
                    capital: null,
                }
            ]
        },
        addForm() {
            this.modeForm = 'add';
            this.$bvModal.show('modal-product-form')

        },
        removeForm(x) {
            if (this.dataList.form.length == 1) {
                return null
            } else {
                return this.dataList.form.splice(x, 1);
            }

        },
        editData(data, type) {
            swal.fire({
                title: 'Apakah Kamu yakin?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ya, ' + type,
                cancelButtonText: 'Batalkan'
            }).then(async result => {
                if (result.value) {
                    // await this.$store.dispatch('DeleteUser', id).then(res => {
                    //     swal.fire(
                    //         'Success',
                    //         'Hapus User ' + nama + ' Berhasil!',
                    //         'success'
                    //     )
                    //     this.loadData()
                    // }).catch(err => {
                    //     swal.fire(
                    //         'Error',
                    //         'Hapus User Gagal!',
                    //         'error'
                    //     )
                    // })
                }
            }).catch(function (error) {
                console.log(error)
            });
        },
        showDetail(data) {
            this.currentId = data.id
            this.$bvModal.show('modal-detail')
        },
        numberFormat(data) {
            return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        loadData(page) {
            let self = this;
            page = page || 1

            self.dataList._loading = true
            self.dataList.data = []
            let searchQ = {
                keyword: self.search.keyword,
                pageSize: Number(this.dataList.limit),
                page: page,
            };

            self.$store.dispatch('getPaymentApproval', searchQ)
                .then(res => {
                    const { data } = res;
                    if (res.data && data.data.length > 0) {
                        data.data.forEach(el => {
                            el.date_formatted = Moment(el.created_at).format('DD MMM YYYY')
                            self.dataList.data.push(el)
                        })
                    }
                    self.dataList.pagination = data.metadata
                    self.total_items = data.metadata.totalData
                    self.dataList._loading = false;
                })
                .catch(() => {
                    self.total_items = 0
                    self.dataList._loading = false;
                });
        },

        getOverallIndex(index) {
            return (this.dataList.pagination.page * this.dataList.limit) - this.dataList.limit + index + 1
        },
    },
    mounted() {
        document.title = 'Approval - Kujangmas Admin'
        this.loadData();
    }
}
</script>