import axios from 'axios'
import basePath from './basePath'

let headers = {
  'Content-Type': 'application/json',
};

let token = localStorage.getItem('accessTokenKujangMasAdmin')

if(token)
{
  headers = Object.assign(headers, {
    Authorization: token ? `Bearer ${token}` : ''
  })
}

let service = axios.create({
  baseURL: basePath.BASE_API_URL,
  headers: headers
})

service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
      const res = response

      // if the custom code is not 20000, it is judged as an error.
      return res

    },
    (error) => {
      if(error.response && error.response.status === 400)
      {
        if(error.response.data.message)
        {
          swal.fire(
            'Error',
            error.message,
            'error'
          )
        }
      }
      else if(error.response && error.response.status === 401)
      {
        swal.fire({
          title: 'Confirm logout',
          text: "You have been logged out, you can cancel to stay on this page, or log in again",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Logout'
        }).then((result) => {
          localStorage.removeItem('accessTokenKujangMasAdmin')
          setTimeout(() => {
            window.location.href = "/login?redirectTo="+window.location.href;
          }, 500)
        })
      }
      else if(error.response && error.response.status === 500)
      {

      }
      else
      {
        swal.fire(
          'Error',
          error.message,
          'error'
        )
        return Promise.reject(error)
      }

      return Promise.reject(error)
    }
)
export default service
