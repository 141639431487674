<!-- StoreItem.vue -->
<template>
    <div class="store-item  align-items-center mb-2 p-2 rounded border">
        <div class="w-100">
            <div class="row mb-2">
                <label class="col-4 mb-0">Store:</label>
                <div class="col">
                    <select v-model="selectedStore" @change="onStoreSelect" class="form-control">
                        <option v-for="store in storeOptions" :key="store.id" :value="store">
                            {{ store.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row mb-2">
                <label class="col-12 mb-1">Diskon:</label>
                <div class="col-12">
                    <div v-for="(disc, idx) in discounts" :key="idx" class="d-flex mb-1">
                        <input type="number" v-model="disc.value" class="form-control form-control-sm" />
                        <select v-model="disc.type" class="form-control form-control-sm ml-1">
                            <option value="rp" selected>
                                Rp
                            </option>
                            <option value="percent">
                                Percent
                            </option>
                        </select>
                        <button class="btn btn-danger btn-small ml-1" @click="removeDiscount(idx)">Hapus</button>
                    </div>
                    <button @click="addDiscount" class="w-100 btn btn-sm btn-light">Tambah diskon</button>
                </div>
            </div>
        </div>
        <div class="d-flex align-items-center">
            <button @click="removeStore" class="btn btn-sm btn-danger ml-auto">Hapus</button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        store: Object
    },
    data() {
        return {
            storeOptions: [
                {
                    id: 13,
                    name: "Roscoco 12 Rcg x 10 Pcs",
                    unit: "Pcs",
                    unit_price: 653
                },
                { id: 2, name: 'Store B', unit: "Pcs", unit_price: 75000 },
                { id: 3, name: 'Store C', unit: "Pcs", unit_price: 60000 },
            ],  // Store fetched stores for dropdown
            selectedStore: null,
            discounts: [],
            total: 0,
            dataList: {
                data: [],
                detailData: {},
                _loading: true,
                pagination: {
                    "page": 1,
                    "total_pages": 1,
                    "total_records": 1,
                    "prev": 1,
                    "next": 1,
                    "record_per_page": 1
                },
                limit: 5,
                form: [
                    {
                        code: '',
                        name: '',
                        unit: '',
                        capital: null,
                    }
                ]
            }
        }
    },
    watch: {
        store: {
            handler: "calculateTotal",
            deep: true,
        },
        discounts: {
            handler: "calculateTotal",
            deep: true,
        },
    },
    emits: ["remove"],
    methods: {
        calculateTotal() {
            let total_diskon = 0
            this.discounts.map((disc) => {
                let discount_amount = disc.value ?? 0;
                if (disc.type === 'percent') {
                    let percent = parseInt(disc.value)
                    discount_amount = this.store.unit_price * percent / 100
                    total_diskon += parseInt(discount_amount)
                } else {
                    total_diskon += parseInt(discount_amount)
                }
            })
            this.total = this.store.quantity * this.store.unit_price - total_diskon;
        },
        loadData(page = 1) {
            this.dataList._loading = true;
            this.storeOptions = [];  // Clear previous data

            const searchQ = {
                keyword: '',
                pageSize: Number(this.dataList.limit),
                page: page,
            };

            this.$store.dispatch('getStores', searchQ)
                .then(res => {
                    const { data } = res;
                    if (data && data.data.length > 0) {
                        this.storeOptions = data.data.map(el => ({
                            ...el,
                            date_formatted: Moment(el.created_at).format('DD MMM YYYY')
                        }));
                    }
                    this.dataList.pagination = data.metadata;
                    // this.total_items = data.metadata.totalData;
                    this.dataList._loading = false;
                })
                .catch(() => {
                    // this.total_items = 0;
                    this.dataList._loading = false;
                });
        },
        // Method to trigger removal of the store
        removeStore() {
            this.$emit("remove");
        },
        // Placeholder for adding discount logic
        addDiscount() {
            this.discounts.push({ value: null,type:"rp" })
        },
        removeDiscount(indexToRemove) {
            this.discounts.splice(indexToRemove, 1);
        },
        onStoreSelect() {
            // You can use selectedStore for further actions
            // console.log('Selected Store:', this.selectedStore);
            this.store.unit_price = this.selectedStore.unit_price
        }
    },
    mounted() {
        // Load store options when the component mounts
        this.loadData();
        // console.log(this.store)
        let selectedIndex = this.storeOptions.findIndex(a => a.id == this.store.store_id)
        // console.log(selectedIndex)
        this.selectedStore = this.storeOptions[selectedIndex]
        this.calculateTotal()
    }
};
</script>

<style scoped>
.store-item {
    background-color: #ffffff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
</style>