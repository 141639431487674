import FormProducts from './Form-Products/Form-Products.vue';
import SwitchActive from './Switch.vue';

export default {
    name: 'Product',
    components : { FormProducts,SwitchActive },
    data() {
        return {
            form:{
                name: 'Veronica',
                company: 'Veridian Dynamics'
            },
            search : {
                keyword : ''
            },
            modeForm: 'add',
            loading: true,
            isMode : 'show',
            total_items: 0,
            currentPage: 1,
            isContent : 'Barang',
            listHead : [
                {
                    title: 'Barang',
                    notif : null
                }
            ],
             options: [
                { value: '', text: 'Pilih Unit', disabled: true },
                { value: 'bal', text: 'bal' },
                { value: 'dus', text: 'dus' },
                { value: 'pak', text: 'pak' },
                { value: 'krg', text: 'krg' },
                { value: 'kg', text: 'kg' },
                { value: 'pcs', text: 'pcs' },
                { value: 'rcg', text: 'rcg' },
                { value: 'peti', text: 'peti' },
            ],
            dataList : {
                data : [],
                detailData: {},
                _loading : true,
                pagination: {
                    "page": 1,
                    "total_pages": 1,
                    "total_records": 1,
                    "prev": 1,
                    "next": 1,
                    "record_per_page": 1
                },
                limit: 5,
                form : [
                    {
                        code : '',
                        name : '',
                        unit : '',
                        capital : null,
                    }
                ]
            }
        }
    },

    watch: {
        // "search.keyword"()
        // {
        //     if(this.search.keyword !== '')
        //     {
        //             this.loadData()
        //     }else
        //     {
        //             this.loadData()
        //     }
        // },
        "currentPage"()
        {
            let self = this

            if(self.currentPage != 1)
            {
                    self.loadData()
            }else {
                    self.loadData()
            }
        },
    },
    computed : {
        totalItem(){

            return this.total_items
        },
    },

    methods: {
        numberFormat(data) {
            return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        closeModal(e) {
            this.$bvModal.hide('modal-product-form')
            this.loadData()
        },
        initForm()
        {
            this.dataList.form = [
                    {
                        code : '',
                        name : '',
                        unit : '',
                        capital : null,
                    }
                ]
        },
        addForm()
        {
            // this.modeForm = 'add';
            // this.$bvModal.show('modal-product-form')
            this.$router.push('/product/add');
            
        },
        removeForm(x)
        {
            if(this.dataList.form.length == 1)
            {
                return null
            }else {
                return  this.dataList.form.splice(x, 1);
            }
            
        },
        editData(id)
        {
            this.$router.push('/product/'+id);
            // this.modeForm = 'edit';
            // this.$store.dispatch('getProductDetail',id)
            // .then(res => {
            //     this.dataList.detailData = res
            //     this.$bvModal.show('modal-product-form')
            // })
        },
        deleteData(id, nama)
        {
            let self = this;
            swal.fire({
                title: 'Are You Sure?',
                text: 'Product '+ nama +' will delete permanently!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel'
                }).then(async result => {
                if (result.value) {
                    await this.$store.dispatch('DeleteProduct',id).then(res => {
                            swal.fire(
                            'Success',
                            'Delete Product '+ nama +' Success!',
                            'success'
                        )
                        this.loadData()
                    }).catch(err => {
                        swal.fire(
                            'Error',
                            'Delete Product Failed!',
                            'error'
                        )
                    })
                    }
                }).catch(function(error) {
                        console.log(error)
                    });
        },
        numberFormat(data) {
            return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        loadData(page)
        {

            let self = this;
            page = page || 1
            
            self.dataList._loading = true
            self.dataList.data = []
            let searchQ = {
                keyword: self.search.keyword,
                pageSize: Number(this.dataList.limit),
                page: page,
            };

            self.$store.dispatch('getProduct', searchQ)
            .then(res => {
                const { data } = res;
                if(res.data && data.data.length > 0)
                {
                    data.data.forEach(el => {
                        el.date_formatted = Moment(el.created_at).format('DD MMM YYYY')
                        self.dataList.data.push(el)
                    })
                }
                self.dataList.pagination = data.metadata
                self.total_items = data.metadata.totalData
                setTimeout(() => {
                    self.dataList._loading = false;
                    
                }, 600);
            })
            .catch(() => {
            self.total_items = 0
                setTimeout(() => {
                    self.dataList._loading = false;
                    
                }, 600);
            });
        },
        getOverallIndex(index) {
            return (this.dataList.pagination.page* this.dataList.limit)-this.dataList.limit + index + 1
          },
    },
    mounted()
    {
      document.title = 'Produk - Kujangmas Admin'
        this.loadData();
    }
}