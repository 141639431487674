<!-- StoreEdit.vue -->
<template>
    <div class="stores-section">
        <div class="d-flex justify-content-between align-items-center mb-3">
            <h6>Diskon Khusus</h6>
            <button @click="addStore" class="btn btn-sm btn-primary">Tambah Diskon Khuusus</button>
        </div>

        <!-- Loop through each store and use StoreItem component -->
        <StoreItem v-for="(each, idx) in form.stores" :key="idx" :store="each" @remove="removeStore(idx)" />

        <!-- Save button
        <div class="d-flex justify-content-between align-items-center mb-3">
            <button @click="saveStores" class="btn btn-sm btn-success ml-auto">Simpan</button>
        </div> -->

        <!-- Message if no stores are present -->
        <div v-if="form.stores.length === 0">
            <b-alert show variant="warning">No items</b-alert>
        </div>
    </div>
</template>

<script>
import StoreItem from './StoreItem.vue';

export default {
    name: "DiskonKhusus",
    components: {
        StoreItem,
    },
    props: {
        form: Object
    },
    data() {
        return {
            noPhoto: "https://via.placeholder.com/60",
        };
    },
    methods: {
        addStore() {
            this.form.stores.push({
                store_name: "",
                quantity: 1,
                unit_price: 0,
                store_image: this.noPhoto,
                notes: "",
            });
        },
        removeStore(index) {
            this.form.stores.splice(index, 1);
        },
        saveStores() {
            // Logic to save stores
            // console.log("Stores saved:", this.form);
            const dataNeed = {
                "destination": {
                    "address": this.form.destination.address,
                    "consignee_contact": this.form.destination.consignee_contact,
                    "consignee_name": this.form.destination.consignee_name
                },
                "discount_amount": this.form.order.discount_amount,
                "id": this.form.order.id,
                "notes": this.form.order.notes,
                "stores": this.form.stores,
                "sales_id": this.form.order.sales_id,
                "store_id": this.form.order.store_id,
                "sub_total_amount": 0,
                "total_amount": 0
            }

            this.$store.dispatch('updateSalesTrx', dataNeed)
            .then(res => {
                swal.fire(
                    'Success!',
                    'Update Transaction Success!',
                    'success'
                    )
            })
            .catch(err => {
                this.$emit("closeModal", true);
                swal.fire(
                    'Failed!',
                    'Update Transaction Failed. '+ err.message,
                    'error'
                )
            })
        },
    },
};
</script>

<style scoped>
.stores-section {
    background-color: #f8f9fa;
    padding: 1rem;
    border-radius: 8px;
}
</style>