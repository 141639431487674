<!-- ProductEdit.vue -->
<template>
    <div class="products-section">
        <div class="d-flex justify-content-between align-items-center mb-3">
            <h6>Produk</h6>
            <button @click="addProduct" class="btn btn-sm btn-primary">Tambah Produk</button>
        </div>

        <!-- Loop through each product and use ProductItem component -->
        <ProductItem v-for="(each, idx) in formData.products" :key="idx" :product="each" @remove="removeProduct(idx)" />

        <!-- Save button -->
        <div class="d-flex justify-content-between align-items-center mb-3">
            <button @click="saveProducts" class="btn btn-sm btn-success ml-auto">Simpan</button>
        </div>

        <!-- Message if no products are present -->
        <div v-if="formData.products.length === 0">
            <b-alert show variant="warning">No items</b-alert>
        </div>
    </div>
</template>

<script>
import ProductItem from './ProductItem.vue';

export default {
    components: {
        ProductItem,
    },
    props: {
        formData: Object
    },
    data() {
        return {
            noPhoto: "https://via.placeholder.com/60",
        };
    },
    methods: {
        addProduct() {
            this.formData.products.push({
                product_name: "",
                quantity: 1,
                unit_price: 0,
                product_image: this.noPhoto,
                notes: "",
            });
        },
        removeProduct(index) {
            this.formData.products.splice(index, 1);
        },
        saveProducts() {
            // Logic to save products
            // console.log("Products saved:", this.formData);
            const dataNeed = {
                "destination": {
                    "address": this.formData.destination.address,
                    "consignee_contact": this.formData.destination.consignee_contact,
                    "consignee_name": this.formData.destination.consignee_name
                },
                "discount_amount": this.formData.order.discount_amount,
                "id": this.formData.order.id,
                "notes": this.formData.order.notes,
                "products": this.formData.products,
                "sales_id": this.formData.order.sales_id,
                "store_id": this.formData.order.store_id,
                "sub_total_amount": 0,
                "total_amount": 0
            }

            this.$store.dispatch('updateSalesTrx', dataNeed)
            .then(res => {
                swal.fire(
                    'Success!',
                    'Update Transaction Success!',
                    'success'
                    )
            })
            .catch(err => {
                this.$emit("closeModal", true);
                swal.fire(
                    'Failed!',
                    'Update Transaction Failed. '+ err.message,
                    'error'
                )
            })
        },
    },
};
</script>

<style scoped>
.products-section {
    background-color: #f8f9fa;
    padding: 1rem;
    border-radius: 8px;
}
</style>