import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import axiosAuth from "@/plugins/axiosAuth";
import base from '@/plugins/basePath';
const qs = require('qs')

axios.defaults.baseURL = base.BASE_API_URL;

Vue.use(Vuex)

let config = {
  headers: {
    'Content-Type': 'application/json'
  }
}

export default new Vuex.Store({
  state: {
    profile: JSON.parse(localStorage.getItem('kujangMasAdminProfile')) || null,
    token: localStorage.getItem('accessTokenKujangMasAdmin') || null,
  },
  getters: {
    loggedIn(state) {
      return state.token !== null
    },
    token(state) {
      return state.token
    },
  },
  mutations: {
    retrieveTokenn(state, token) {
      state.token = token
    },
    retrieveTokenStream(state, token) {
      state.tokenStream = token
    },
    logout(state) {
      localStorage.removeItem('kujangMasAdminProfile')
      localStorage.removeItem('accessTokenKujangMasAdmin')
      state.token = null
      state.tokenStream = null
      state.profile = null
    },
  },
  actions: {
    login(context, data) {
      return new Promise((resolve, reject) => {
        config = {
          headers: {
            'Content-Type': 'application/json',
          },
        }

        axios.post('admin/login', data, config)
          .then(response => {
            const { token } = response.data.data
              localStorage.setItem('accessTokenKujangMasAdmin', token)
              context.commit('retrieveTokenn', token)

              resolve(response.data)
            })
          .catch(error => {
            reject(error)
          })
      })
    },
    logout(context) {
      return context.commit('logout')
    },

    //Master Data
    getProductCat(context, payload) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.post(`general/product-categories/filter`, payload, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    uploadFile(context, data) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.post(`upload/image`, data, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },


    //Warehouse Stock
    getWarehouseStock(context, data) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.post(`admin/warehouse-stocks/filter`, data, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    addWarehouseStock(context, data) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.post(`admin/warehouse-stocks`, data, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    UpdateWarehouseStock(context, data) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.put(`admin/warehouse-stocks/${data.id}/restock`, data.form, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    UpdateWarehouseStockType(context, data) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.put(`admin/warehouse-stocks/${data.id}/move`, data.form, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    
    DeleteWarehouseStock(context, id) {
      config = {
        headers: {
          // 'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.delete(`admin/warehouse-stocks/`+ id, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    //Warehouse
    getWarehouse(context, data) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.post(`admin/warehouses/filter`, data, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    addWarehouse(context, data) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.post(`admin/warehouses`, data, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    UpdateWarehouse(context, data) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.put(`admin/warehouses/`+ data.id, data.form, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    
    DeleteWarehouse(context, id) {
      config = {
        headers: {
          // 'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.delete(`admin/warehouses/`+ id, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    //Product
    getProduct(context, data) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.post(`admin/products/filter`, data, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getProductDetail(context, id) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.get(`admin/products/`+ id, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    addProduct(context, data) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.post(`admin/products`, data, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    UpdateProduct(context, data) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.put(`admin/products/`+ data.id, data.form, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    
    DeleteProduct(context, id) {
      config = {
        headers: {
          // 'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.delete(`admin/products/`+ id, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    //Promo
    getPromo(context, data) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.post(`admin/promotions/filter`, data, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getPromoDetail(context, id) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.get(`admin/promotions/`+ id, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    addPromo(context, data) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.post(`admin/promotions`, data, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    UpdatePromo(context, data) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.put(`admin/promotions/`+ data.id, data.form, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    
    DeletePromo(context, id) {
      config = {
        headers: {
          // 'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.delete(`admin/promotions/`+ id, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    //Stores
    getStores(context, data) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.post(`admin/stores/filter`, data, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    addStore(context, data) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.post(`admin/stores`, data, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    UpdateStore(context, data) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.put(`admin/stores/`+ data.id, data.form, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    UpdateStatusStore(context, data) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.put(`admin/stores/${data.id}/status`, data.form, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    ToggleStatusStore(context, data) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.post(`admin/stores/${data.id}/toggle-active`, {}, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    ToggleStatusProduct(context, data) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.post(`admin/products/${data.id}/toggle-active`, {}, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    
    DeleteStore(context, id,reason) {
      config = {
        data: {
          reason : reason
        },
        headers: {
          // 'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.delete(`admin/stores/`+ id, config)
          .then(response => {
            console.log(response)
            resolve(response.data)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },

    //Users
    getUsers(context, data) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.post(`admin/users/filter`, data, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getUsersDetail(context, id) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.get(`admin/users/`+ id, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    addUser(context, data) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.post(`admin/users`, data, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    UpdateUser(context, data) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.put(`admin/users/`+ data.id, data.form, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    
    DeleteUser(context, id) {
      config = {
        headers: {
          // 'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.delete(`admin/users/`+ id, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    //Payment Approval
    getPaymentApproval(context, payload) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.post(`admin/payment/filter`, payload, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    paymentApproval(context, payload) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.post(`admin/payment/${payload.id}/approval`, payload.data, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getPaymentDetailApproval(context, payload) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.get(`admin/payment/${payload.id}`, payload, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    //Transaction
    getTransaction(context, data) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.post(`admin/orders/filter`, data, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getTransactionDetail(context, id) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.get(`admin/orders/`+ id, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getInvoicePdf(context, id) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.get(`admin/orders/${id}/get-invoice-pdf`, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    updateStatusTrx(context, data) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.put(`admin/orders/status`, data, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    updateSalesTrx(context, data) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.put(`sales/orders/${data.id}/update`, data, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    exportExcel(context, data) {
      config = {
        headers: {
          'responseType':'blob',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.post(`admin/orders/export-excel`, data, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    //Variant 
    createVariant(context, data) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.post(`admin/products/${data.productId}/variants`, data.form, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getVariantStockList(context, paramsQ) {
      config = {
        params : paramsQ,
        headers: {
        'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.get(`admin/products/variants`, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getVariantDiscount(context, variantId) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.get(`admin/products/variants/${variantId}/discounts`, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    saveVariantDiscount(context, data) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.post(`admin/products/variants/${data.variantId}/discounts`, data.form, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
   
    updateVariantDiscount(context, data) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.put(`admin/products/variants/discounts/${data.id}`, data.form, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    updateVariantData(context, data) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.put(`admin/products/variants/${data.id}/${data.type}`, data.form, config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    DeleteVariantDiscount(context, id) {
      config = {
        headers: {
          // 'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.delete(`admin/products/variants/discounts/${id}` , config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    DeleteVariant(context, id) {
      config = {
        headers: {
          // 'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.token,
        },
      }
      return new Promise((resolve, reject) => {
        axiosAuth.delete(`admin/products/variants/${id}` , config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
  modules: {
  }
})
