import FormStore from './Form-Store/Form-Store.vue';
import Switches from 'vue-switches';
import SwitchActive from './Switch.vue';

export default {
    name: 'Store',
    components : { FormStore,SwitchActive },
    data() {
        return {
            enabled: false,
            form:{
                name: 'Veronica',
                company: 'Veridian Dynamics'
            },
            formPass: {
                new_password: null,
                retry_password: null,
            },
            search : {
                keyword : ''
            },
            modeForm: 'add',
            loading: true,
            isMode : 'show',
            total_items: 0,
            currentPage: 1,
            isContent : 'Barang',
            listHead : [
                {
                    title: 'Barang',
                    notif : null
                }
            ],
             options: [
                { value: '', text: 'Pilih Unit', disabled: true },
                { value: 'bal', text: 'bal' },
                { value: 'dus', text: 'dus' },
                { value: 'pak', text: 'pak' },
                { value: 'krg', text: 'krg' },
                { value: 'kg', text: 'kg' },
                { value: 'pcs', text: 'pcs' },
                { value: 'rcg', text: 'rcg' },
                { value: 'peti', text: 'peti' },
            ],
            dataList : {
                data : [],
                detailData: {},
                _loading : true,
                pagination: {
                    "page": 1,
                    "total_pages": 1,
                    "total_records": 1,
                    "prev": 1,
                    "next": 1,
                    "record_per_page": 1
                },
                limit: 5,
                form : [
                    {
                        code : '',
                        name : '',
                        unit : '',
                        capital : null,
                    }
                ]
            },
            notSame: false,
        }
    },

    watch: {
        "currentPage"()
        {
            let self = this

            if(self.currentPage != 1)
            {
                    self.loadData()
            }else {
                    self.loadData()
            }
        },
    },
    computed : {
        totalItem(){

            return this.total_items
        },
        disabledSave() {
            let valid = false;

            if(this.formPass.new_password === '' || this.formPass.new_password === null) {
                valid = true;
            }
            if(this.formPass.retry_password === '' || this.formPass.retry_password === null) {
                valid = true;
            }

            return valid
        }
    },

    methods: {
        numberFormat(data) {
            return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        closeModal(e) {
            this.$bvModal.hide('modal-product-form')
            this.loadData()
        },
        openModalPass(data) {
            this.dataList.detailData = data;
            this.formPass = {
                new_password: null, 
                retry_password: null, 
            }
            this.$bvModal.show('modal-change-password')
        },
        initForm()
        {
            this.dataList.form = [
                    {
                        code : '',
                        name : '',
                        unit : '',
                        capital : null,
                    }
                ]
        },
        addForm()
        {
            this.modeForm = 'add';
            this.$bvModal.show('modal-product-form')
            
        },
        removeForm(x)
        {
            if(this.dataList.form.length == 1)
            {
                return null
            }else {
                return  this.dataList.form.splice(x, 1);
            }
            
        },
        editData(data)
        {
            this.modeForm = 'edit';
            // this.$store.dispatch('getUsersDetail',id)
            // .then(res => {
            //     res.date_formatted = Moment(res.date_formatted).format('DD MMM YYYY')
            //     this.dataList.detailData = res
            //     this.$bvModal.show('modal-product-form')
            // })

            this.dataList.detailData = data;
            this.$bvModal.show('modal-product-form')
        },
        deleteData(id, nama)
        {
            swal.fire({
                title: 'Apakah Kamu yakin?',
                text: 'Toko '+ nama +' akan dihapus secara permanen!',
                input:'text',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Hapus',
                cancelButtonText: 'Batalkan'
                }).then(async result => {
                if (result.value) {
                    await this.$store.dispatch('DeleteStore',id).then(res => {
                            swal.fire(
                            'Success',
                            'Hapus Toko '+ nama +' Berhasil!',
                            'success'
                        )
                        this.loadData()
                    }).catch(err => {
                        console.log(err)
                        swal.fire(
                            'Error',
                            'Hapus Toko Gagal!',
                            'error'
                        )
                    })
                    }
                }).catch(function(error) {
                        console.log(error)
                    });
        },
        approveData(id, nama)
        {
          
            swal.fire({
                title: 'Apakah Kamu yakin?',
                text: 'Kamu akan menyetujui pengajuan Toko '+ nama ,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ya, Setujui',
                cancelButtonText: 'Batalkan'
                }).then(async result => {
                if (result.value) {
                    const dataNeed = {
                        id: id,
                        form: {
                            id: id,
                            status: 'approved'
                        }
                    }
                    await this.$store.dispatch('UpdateStatusStore',dataNeed).then(res => {
                            swal.fire(
                            'Success',
                            'Setujui Toko '+ nama +' Berhasil!',
                            'success'
                        )
                        this.loadData()
                    }).catch(err => {
                        swal.fire(
                            'Error',
                            'Setujui Toko Gagal!',
                            'error'
                        )
                    })
                    }
                }).catch(function(error) {
                        console.log(error)
                    });
        },
        rejectData(id, nama)
        {
          
            swal.fire({
                title: 'Apakah Kamu yakin?',
                text: 'Kamu akan Menolak pengajuan Toko '+ nama ,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ya, Tolak',
                cancelButtonText: 'Batalkan'
                }).then(async result => {
                if (result.value) {
                    const dataNeed = {
                        id: id,
                        form: {
                            id: id,
                            status: 'rejected'
                        }
                    }
                    await this.$store.dispatch('UpdateStatusStore',dataNeed).then(res => {
                            swal.fire(
                            'Success',
                            'Menolak Toko '+ nama +' Berhasil!',
                            'success'
                        )
                        this.loadData()
                    }).catch(err => {
                        swal.fire(
                            'Error',
                            'Menolak Toko Gagal!',
                            'error'
                        )
                    })
                    }
                }).catch(function(error) {
                        console.log(error)
                    });
        },
        numberFormat(data) {
            return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        loadData(page)
        {
            let self = this;
            page = page || 1

            self.dataList._loading = true
            self.dataList.data = []
            let searchQ = {
                keyword: self.search.keyword,
                pageSize: Number(this.dataList.limit),
                page: page,
            };

            self.$store.dispatch('getStores', searchQ)
            .then(res => {
                const { data } = res;
                if(res.data && data.data.length > 0)
                {
                    data.data.forEach(el => {
                        // el.status = 'pending_approval'
                        el.date_formatted = Moment(el.created_at).format('DD MMM YYYY')
                        self.dataList.data.push(el)
                    })
                }
            self.dataList.pagination = data.metadata
            self.total_items = data.metadata.totalData
            self.dataList._loading = false;
            })
            .catch(() => {
            self.total_items = 0
            self.dataList._loading = false;
            });
        },

        getOverallIndex(index) {
            return (this.dataList.pagination.page* this.dataList.limit)-this.dataList.limit + index + 1
          },



        async saveData() {
            let self = this;
            this.notSame = false;

            let dataNeed = {
                id : this.dataList.detailData.id,
                form : this.formPass
            }

            if (this.formPass.new_password !== this.formPass.retry_password) {
                this.notSame = true;
                return;
            }

            delete dataNeed.form.retry_password;

            self.$store.dispatch('UpdatePassCourier', dataNeed)
            .then(res => {
                this.$bvModal.hide('modal-change-password')
                swal.fire(
                    'Success!',
                    'Update Password Success!',
                    'success'
                    )
            })
            .catch(err => {
                this.$emit("closeModal", true);
                swal.fire(
                    'Failed!',
                    'Update Password Failed. '+ err.message,
                    'error'
                )
            })
        },
    },
    mounted()
    {
      document.title = 'Toko - Kujangmas Admin'
        this.loadData();
    }
}